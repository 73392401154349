import React from 'react';
import { graphql } from 'gatsby';
import '../styles/pages/404.scss';

import Layout from '../components/layout';
import SEO from '../components/seo';

function NotFoundPage({ location, data }) {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <div className="not_found_page">
        <h1>Not Found</h1>
        <p>This Page is coming soon!</p>
      </div>
    </Layout>
  );
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
